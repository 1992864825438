import { createEffect, createSignal, For, mergeProps, Show } from 'solid-js';
import {
  getDaysGrid,
  getWeekdays,
  idMaker,
  isCurrentMonth,
  isSameDate,
  getDateFormat,
  checkIsDisabled,
  getDefaultPlaceholder,
} from './lib/helpers';
import { DateCell, DatepickerProps, DateSchema, Theme, LogicCell } from './lib/types';
import {
  MONTH_DECREMENT_ICON,
  MONTH_INCREMENT_ICON,
  YEAR_DECREMENT_ICON,
  YEAR_INCREMENT_ICON,
} from './icons';

import {
  DatePickerContainer,
  WeekdayCell,
  CalendarHeader,
  CalendarButtonGroup,
  CalendarCell,
  CalendarGrid,
  CalendarPopup,
  DivButton,
  DayElement,
} from './StyledComponents';

let DEFAULT_THEME: Theme = 'light';
if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
  DEFAULT_THEME = 'dark';
}

const DEFAULT_PROPS: DatepickerProps = {
  value: null,
  color: '#009898',
  //icon: DEFAULT_ICON,
  hint: '',
  initialDate: new Date(),
  min: null,
  max: null,
  delimiter: '/',
  inputWidth: 630,
  errorMessage: 'invalid date',
  filter: (d: Date) => true,
  onDateSelected: (d: Date | null) => d, // both input and calendar,
  onInput: (e: any) => e, // input input,
  onChange: (e: any) => e, // input change,
  dateClass: (d: Date) => '',
  label: 'date picker',
  placeholder: '',
  theme: DEFAULT_THEME,
  applyMask: true,
  disabled: false,
  inputDisabled: false,
  calendarDisabled: false,
  closeAfterClick: true,
  hideYearButtons: false,
  locale: 'en-GB',
  type: 'datePicker',
  touchUIMode: false,
  calendarOnly: false, // no input, calendar onl;
  show:false
};

export function DatePicker(props: DatepickerProps) {
  props = mergeProps(DEFAULT_PROPS, props, {
    placeholder: props.placeholder || getDefaultPlaceholder(props.locale!, props.delimiter!),
  });

  // const id = `calendar-popup-${idMaker()}`;
  let inputRef!: HTMLInputElement,
    calendarPopupRef!: HTMLDivElement,
    iconBtnRef!: HTMLDivElement,
    monthDecrBtn!: HTMLDivElement,
    monthIncrBtn!: HTMLDivElement,
    yearDecrBtn!: HTMLDivElement,
    yearIncrBtn!: HTMLDivElement;
  let timeout: any;
  let grid: DateCell[] = [];
  let cellsRefs: HTMLDivElement[] = [];
  let cellsLogicGrid: LogicCell[] = [];

  const [isOpen, setIsOpen] = createSignal(props.show);
  const [inputFocused, setInputFocused] = createSignal(false);

  createEffect(()=>{
    setIsOpen(props.show)
  })
  const [shownDate, setShownDate] = createSignal(props.initialDate || props.value || new Date());

  const getDateSchema = () => {
    const dateFormat = getDateFormat(props.value || new Date(), props.locale, props.delimiter);
    return dateFormat.replaceAll(props.delimiter || '/', '') as DateSchema; // YMD | MDY | DMY
  };
  const getCurrentMonthYear = () => {
    return (shownDate() || new Date()).toLocaleDateString(props.locale, {
      month: 'short',
      year: 'numeric',
    });
  };
  const daysGrid = (date: Date | null): DateCell[] => {
    let d = date || new Date();
    grid = getDaysGrid(d, props.locale, props.delimiter);

    return grid;
  };

  // KEYBOARD NAVIGATION HELPERS
  const getCellBtnEl = (index: number): HTMLButtonElement => {
    let cell = cellsRefs[index]?.firstChild as HTMLButtonElement;

    return cell;
  };
  const findNextInColumn = (col: number, day = 0) => {
    return cellsLogicGrid.find((c) => c.col === col && c.day > day && !c.disabled);
  };
  const findPrevInColumn = (col: number, day = 32) => {
    return cellsLogicGrid
      .slice()
      .reverse()
      .find((c) => c.col === col && c.day < day && !c.disabled);
  };
  const findNextInRow = (row: number, day: number) => {
    return cellsLogicGrid.find((c) => c.row === row && c.day > day && !c.disabled);
  };
  const findPrevInRow = (row: number, day: number) => {
    return cellsLogicGrid
      .slice()
      .reverse()
      .find((c) => !c.disabled && c.row === row && c.day < day);
  };
  const findNextAvailable = (day = 0) => {
    return cellsLogicGrid.find((c) => !c.disabled && day < c.day);
  };
  const findPrevAvailable = (day = 32) => {
    return cellsLogicGrid
      .slice()
      .reverse()
      .find((c) => !c.disabled && day > c.day);
  };

  const focusHeaderButton = (cell: LogicCell) => {
    const colMapping = {};
    if (props.hideYearButtons) {
      colMapping[0] = monthDecrBtn;
      colMapping[1] = monthDecrBtn;
      colMapping[2] = monthDecrBtn;
      colMapping[3] = monthIncrBtn;
      colMapping[4] = monthIncrBtn;
      colMapping[5] = monthIncrBtn;
      colMapping[6] = monthIncrBtn;
    } else {
      colMapping[0] = yearDecrBtn;
      colMapping[1] = monthDecrBtn;
      colMapping[2] = monthDecrBtn;
      colMapping[3] = monthIncrBtn;
      colMapping[4] = monthIncrBtn;
      colMapping[5] = monthIncrBtn;
      colMapping[6] = yearIncrBtn;
    }

    const headerBtn = colMapping[cell.col];
    headerBtn.focus();
  };

  function handleMonthDecrKeyDown(e: KeyboardEvent) {
    switch (e.code) {
      case 'Escape': {
        setIsOpen(false);
        break;
      }
      case 'Tab': {
        if (e.shiftKey && props.hideYearButtons) {
          setIsOpen(false);
          setTimeout(() => iconBtnRef.focus(), 300);
        }
        break;
      }
      case 'ArrowUp': {
        setIsOpen(false);
        if (props.inputDisabled) {
          iconBtnRef.focus();
        } else {
          inputRef.focus();
        }
        break;
      }
      case 'ArrowLeft': {
        yearDecrBtn && yearDecrBtn.focus();
        break;
      }
      case 'ArrowRight': {
        monthIncrBtn.focus();
        break;
      }
      case 'ArrowDown': {
        let nextLogicCell = props.hideYearButtons ? findNextInColumn(0) : findNextInColumn(1);

        if (!nextLogicCell) {
          nextLogicCell = findNextAvailable();
        }

        if (nextLogicCell) {
          getCellBtnEl(nextLogicCell.day - 1).focus();
        }
      }
    }
  }
  function handleMonthIncrKeyDown(e: KeyboardEvent) {
    switch (e.code) {
      case 'Escape': {
        setIsOpen(false);
      }
      case 'Tab': {
        // can be better!
        if (!e.shiftKey && props.hideYearButtons) {
          setIsOpen(false);
          setTimeout(() => iconBtnRef.focus(), 200);
        }
      }
      case 'ArrowUp': {
        setIsOpen(false);
        iconBtnRef.focus();
        break;
      }
      case 'ArrowLeft': {
        monthDecrBtn.focus();
        break;
      }
      case 'ArrowRight': {
        yearIncrBtn && yearIncrBtn.focus();
        break;
      }

      case 'ArrowDown': {
        let nextLogicCell = props.hideYearButtons ? findNextInColumn(6) : findNextInColumn(5);

        if (!nextLogicCell) {
          nextLogicCell = findNextAvailable();
        }

        if (nextLogicCell) {
          getCellBtnEl(nextLogicCell.day - 1).focus();
        }
      }
    }
  }
  function handleYearDecrKeyDown(e: KeyboardEvent) {
    switch (e.code) {
      case 'Escape': {
        setIsOpen(false);
        break;
      }
      case 'Tab': {
        if (e.shiftKey) {
          setIsOpen(false);
          setTimeout(() => iconBtnRef.focus(), 300);
        }
        break;
      }
      case 'ArrowUp': {
        setIsOpen(false);
        if (props.inputDisabled) {
          iconBtnRef.focus();
        } else {
          inputRef.focus();
        }
        break;
      }
      case 'ArrowRight': {
        monthDecrBtn.focus();
        break;
      }
      case 'ArrowDown': {
        let nextLogicCell = findNextInColumn(0);

        if (!nextLogicCell) {
          nextLogicCell = findNextAvailable();
        }

        if (nextLogicCell) {
          getCellBtnEl(nextLogicCell.day - 1).focus();
        }
      }
    }
  }
  function handleYearIncrKeyDown(e: KeyboardEvent) {
    // console.log(document.activeElement);

    switch (e.code) {
      case 'Escape': {
        setIsOpen(false);
      }
      case 'ArrowUp': {
        setIsOpen(false);
        iconBtnRef.focus();
        break;
      }
      case 'ArrowLeft': {
        monthIncrBtn.focus();
        break;
      }
      case 'ArrowDown': {
        let nextLogicCell = findNextInColumn(6);

        if (!nextLogicCell) {
          nextLogicCell = findNextAvailable();
        }

        if (nextLogicCell) {
          getCellBtnEl(nextLogicCell.day - 1).focus();
        }
      }
    }
  }

  function handleCellKeyDown(e: KeyboardEvent, d: DateCell) {
    const currLogicCell = cellsLogicGrid.find((c) => c.day === d.day)!;

    switch (e.code) {
      case 'Escape': {
        inputRef.focus();
        setIsOpen(false);
        break;
      }
      case 'Tab': {
        const lastCell = findPrevAvailable();
        if (currLogicCell.pos === lastCell?.pos) {
          setIsOpen(false);
        }
        break;
      }
      case 'ArrowUp': {
        let nextLogicCell = findPrevInColumn(currLogicCell.col, currLogicCell.day);
        if (!nextLogicCell) {
          focusHeaderButton(currLogicCell);
        }

        if (nextLogicCell) {
          getCellBtnEl(nextLogicCell.day - 1).focus();
        }
        break;
      }
      case 'ArrowRight': {
        let nextLogicCell = findNextInRow(currLogicCell.row, currLogicCell.day);

        if (!nextLogicCell) {
          nextLogicCell = findNextAvailable(currLogicCell.day);
        }

        if (nextLogicCell) {
          getCellBtnEl(nextLogicCell.day - 1).focus();
        }
        break;
      }
      case 'ArrowDown': {
        let nextLogicCell = findNextInColumn(currLogicCell.col, currLogicCell.day);

        if (nextLogicCell) {
          getCellBtnEl(nextLogicCell.day - 1).focus();
        }
        break;
      }
      case 'ArrowLeft': {
        let nextLogicCell = findPrevInRow(currLogicCell.row, currLogicCell.day);

        if (!nextLogicCell) {
          nextLogicCell = findPrevAvailable(currLogicCell.day);
        }

        if (nextLogicCell) {
          getCellBtnEl(nextLogicCell.day - 1).focus();
        }
        break;
      }
    }
  }
  function handleDateSelect(d:DateCell){
  props.onDateSelected(d.date)
   setIsOpen(false)
  }

  // HEADER BUTTON ACTIONS
  const yearDecrement = (e) => {
    cellsRefs = [];
    const timestamp = shownDate().getTime() - 1000 * 60 * 60 * 24 * 365.25;
    setShownDate(new Date(timestamp));
  };
  const monthDecrement = (e) => {
    cellsRefs = [];
    const currentDay = shownDate().getDate(),
      currentMonth = shownDate().getMonth(),
      currentYear = shownDate().getFullYear();

    const prevMonth = currentMonth === 0 ? 11 : currentMonth - 1;

    const prevDate = new Date(
      prevMonth === 11 ? currentYear - 1 : currentYear,
      prevMonth,
      currentDay,
    );

    setShownDate(prevDate);
  };
  const monthIncrement = (e) => {
    cellsRefs = [];

    const currentDay = shownDate().getDate(),
      currentMonth = shownDate().getMonth(),
      currentYear = shownDate().getFullYear();

    const nextMonth = currentMonth === 11 ? 0 : currentMonth + 1;

    const lastDayInMonth = new Date(
      // prettier-ignore
      new Date(
               currentMonth === 11 ? currentYear + 1 : currentYear,
               nextMonth,
               1
           ).getTime() - 1000 * 60,
    ).getDate();

    const nextDay = currentDay < lastDayInMonth ? currentDay : lastDayInMonth;

    const nextDate = new Date(nextMonth === 0 ? currentYear + 1 : currentYear, nextMonth, nextDay);

    setShownDate(nextDate);
  };
  const yearIncrement = (e) => {
    cellsRefs = [];
    const timestamp = shownDate().getTime() + 1000 * 60 * 60 * 24 * 365.25;
    setShownDate(new Date(timestamp));
  };

  createEffect(() => {
    // console.log({ shownDate: shownDate() });

    if (!isOpen()) {
      cellsRefs = [];
    } else {
      cellsLogicGrid = [];

      for (let cell of cellsRefs) {
        const { disabled, grid_pos, grid_col, grid_row, day, weekday } = cell.dataset;

        const cellInfo = {
          pos: Number(grid_pos),
          col: Number(grid_col),
          row: Number(grid_row),
          day: Number(day),
          weekday: Number(weekday),
          disabled: disabled === 'true' ? true : false,
        };

        cellsLogicGrid.push(cellInfo);
      }
    }
  });

  createEffect(() => {
    if (props.value) {
      clearTimeout(timeout);
      setInputFocused(true);
    }
  });


  return (
    <DatePickerContainer theme={props.theme}>
  

        <Show when={isOpen()}>
          <CalendarPopup ref={calendarPopupRef} theme={props.theme}>
            {/* CALENDAR HEADER */}
            <CalendarHeader theme={props.theme}>
              <CalendarButtonGroup theme={props.theme}>
                <Show when={!props.hideYearButtons}>
                  <DivButton
                    ref={yearDecrBtn}
                    onClick={yearDecrement}
                    onKeyDown={handleYearDecrKeyDown}
                  >
                    <YEAR_DECREMENT_ICON />
                  </DivButton>
                </Show>
                <DivButton
                  ref={monthDecrBtn}
                  onClick={monthDecrement}
                  onKeyDown={handleMonthDecrKeyDown}
                >
                  <MONTH_DECREMENT_ICON />
                </DivButton>
              </CalendarButtonGroup>

              {/* CALENDAR MONTH YEAR */}
              <h3 class='foucs:outline-none' >{getCurrentMonthYear()}</h3>
             

              <CalendarButtonGroup theme={props.theme}>
                <DivButton
                  ref={monthIncrBtn}
                  onClick={monthIncrement}
                  onKeyDown={handleMonthIncrKeyDown}
                >
                  <MONTH_INCREMENT_ICON />
                </DivButton>
                
                <Show when={!props.hideYearButtons}>
                  <DivButton
                    ref={yearIncrBtn}
                    onClick={yearIncrement}
                    onKeyDown={handleYearIncrKeyDown}
                    
                  >
                    <YEAR_INCREMENT_ICON />
                  </DivButton>
                </Show>
              </CalendarButtonGroup>
            </CalendarHeader>

            {/* DATE CELLS */}
            <CalendarGrid theme={props.theme}>
              <For each={getWeekdays(props.locale, 'short')}>
                {(weekday) => <WeekdayCell theme={props.theme}>{weekday}</WeekdayCell>}
              </For>
              <For each={daysGrid(shownDate())}>
                {(d) => {
                  let cellRef!: HTMLDivElement;
                  let disabled = checkIsDisabled(d, props.filter, props.min, props.max);

                  const cellElement = (
                    <CalendarCell
                      ref={cellRef}
                      id={idMaker()}
                      class="calendar-cell"
                      theme={props.theme}
                      color={props.color}
                      data-grid_pos={d.gridPos}
                      // prettier-ignore
                      data-grid_row={Math.floor(d.gridPos / 7 )}
                      data-grid_col={d.gridPos % 7}
                      data-day={d.day}
                      data-weekday={d.weekday}
                      data-disabled={disabled}
                      onClick={(e) => handleDateSelect(d)}
                      onKeyDown={(e) => handleCellKeyDown(e, d)}
                      isVisible={isCurrentMonth(d.date, shownDate())}
                      isSelected={!!props.value && isSameDate(d.date, props.value)}
                      disabled={disabled}
                    >
                      
                      <DayElement disabled={disabled} selected={!!props.value && isSameDate(d.date, props.value)}>{d.day}</DayElement>
                    </CalendarCell>
                  );

                  d.date.getMonth() === shownDate().getMonth() && cellsRefs.push(cellRef);


                  return cellElement;
                }}
              </For>
            </CalendarGrid>
          </CalendarPopup>
        </Show>

    </DatePickerContainer>
  );
}